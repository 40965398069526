import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acroym',
  standalone: true
})
export class AcroymPipe implements PipeTransform {

  transform(value: string, isProfile: boolean = false): string {
    if(!value) return '';
    let output = value?.match(/\b\w/g)?.join('')?.toUpperCase() || '';
    if (output?.length > 3) {
      output = isProfile ? output?.slice(0, 1) : (output?.slice(0, 3) + '..')
    }
    return output;
  }

}
